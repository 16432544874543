import React, { memo, type ReactNode, useState } from "react";
import { type OpeningCostsAndChargesResponseFormatted } from "../../../types/opening-costs-and-charges-response";
import { InstrumentType } from "../../../types/instrument-type";
import { type ClosingCostsAndChargesResponseFormatted } from "../../../types/closing-costs-and-charges-response";
import { useSession } from "../../../hooks/use-session";
import { convertLocalToCurrencySymbol } from "../../../utils/currency-local-format";
import { Trans, useTranslation } from "react-i18next";
import { useETPTheme } from "../../../hooks/use-etp-theme";
import { type ETPtheme } from "../../../theme/etp-theme";
import { CostsAndChargesDetailsContainer } from "./CostsAndChargesDetailsStyles";
import CloseIcon from "../../icons/CloseIcon";
import PlusMinusIcon, { PlusMinusIconType } from "../../icons/PlusMinus";
import { wtpGet } from "../../../adapters/wtp-fetch-adapter";
import { Button } from "ig-phoenix";
import Tooltip, {
  PointerLocation,
  TooltipContent,
  xAxis,
  yAxis,
} from "../../generics/tooltip/Tooltip";

const getEntryCost = (
  costsAndChargesData: OpeningCostsAndChargesResponseFormatted,
  instrumentType: InstrumentType,
) => {
  if (instrumentType === InstrumentType.CONSTANT_LEVERAGE) {
    return costsAndChargesData.etpEntryCost;
  } else {
    return costsAndChargesData.knockoutPremiumDeposit;
  }
};
const getExitCost = (
  costsAndChargesData: ClosingCostsAndChargesResponseFormatted,
  instrumentType: InstrumentType,
) => {
  if (instrumentType === InstrumentType.CONSTANT_LEVERAGE) {
    return costsAndChargesData.etpExitCost;
  } else {
    return costsAndChargesData.knockoutPremiumReturn;
  }
};

export type CostsAndChargesData =
  | OpeningCostsAndChargesResponseFormatted
  | ClosingCostsAndChargesResponseFormatted;

interface CostsAndChargesDetailsProps {
  instrumentName: string;
  instrumentType: InstrumentType;
  costsAndChargesData: CostsAndChargesData;
  onClose: () => void;
}

const initialCostsSectionState = {
  opening: false,
  closing: false,
  daily: false,
};

interface CostExpandedKey {
  opening: boolean;
  closing: boolean;
  daily: boolean;
}

interface ExpandableSectionProps {
  children: ReactNode;
  section: keyof CostExpandedKey;
  costExpanded: boolean;
  updateCostsSectionState: () => void;
  total: string;
}

function extracted(blob: Blob, indicativeQuoteReference: string) {
  const objectUrl: string = URL.createObjectURL(blob);
  const a: HTMLAnchorElement = document.createElement("a");
  a.setAttribute("href", objectUrl);
  a.setAttribute("download", `${indicativeQuoteReference}.pdf`);
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  URL.revokeObjectURL(objectUrl);
}

const CostsAndChargesDetails = ({
  instrumentName,
  instrumentType,
  costsAndChargesData,
  onClose,
}: CostsAndChargesDetailsProps) => {
  const ETPtheme: ETPtheme = useETPTheme();
  const { sessionData } = useSession();

  const { t } = useTranslation();

  const [costsExpanded, setCostsExpanded] = useState<CostExpandedKey>(
    initialCostsSectionState,
  );
  const session = useSession();

  const translate = (key: string) => {
    return t(`costs-and-charges.details.${key}`);
  };

  const isTicketForOpening =
    costsAndChargesData.costsAndChargesType === "opening";

  const convertAmount = (amount: number | undefined) => {
    return convertLocalToCurrencySymbol(
      amount,
      sessionData.clientLocale,
      costsAndChargesData.currencyCodeISO,
    );
  };

  const updateCostsSectionState = (section: keyof CostExpandedKey) => {
    setCostsExpanded({
      ...initialCostsSectionState,
      [section]: !costsExpanded[section],
    });
  };
  const downloadPdfHook = () => {
    void wtpGet(
      `/wtp-gateway/durablemedium/${costsAndChargesData.indicativeQuoteReference}`,
      session,
    )
      .then(async (response) => {
        if (response.ok) {
          return await response.blob();
        }
        return await response.text().then((text) => {
          throw new Error(text);
        });
      })
      .then((blob) => {
        if (blob != null) {
          extracted(blob, costsAndChargesData.indicativeQuoteReference);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <CostsAndChargesDetailsContainer
      data-testid="costs-and-charges-details"
      $ETPtheme={ETPtheme}
    >
      <div className="header">
        <p>{translate("header")}</p>
      </div>

      <div className="body">
        <CloseIcon onClose={onClose} width={13} height={13} offset={18} />
        <h2>{translate("indicative-costs-and-charges")}</h2>
        <p>
          <Trans
            i18nKey={
              "costs-and-charges.details.estimated-cost-disclaimer.content"
            }
          >
            {/* The text below will not be rendered, it will be interpolated */}
            text
            <a
              href={translate("estimated-cost-disclaimer.link-url")}
              target="_blank"
              rel="noreferrer"
              className="etp-quick-deal_external-link etp-quick-deal_external-link--with-icon"
            >
              link
            </a>
          </Trans>
        </p>
        <br />
        <p>{translate("minus-sign-explanation")}</p>

        <p
          className="instrument-name"
          data-testid="costs-and-charges-details_instrument-name"
        >
          {instrumentName}
        </p>

        <section>
          <ul>
            {isTicketForOpening && (
              <ExpandableSection
                section="opening"
                costExpanded={costsExpanded.opening}
                updateCostsSectionState={() => {
                  updateCostsSectionState("opening");
                }}
                total={convertAmount(costsAndChargesData.totalOpening)}
              >
                <h3>{translate("product-costs")}</h3>
                <dl>
                  {costsAndChargesData.openingIFTT != null && (
                    <>
                      <dt id="costs-and-charges-details_openingIFTT">
                        {translate("iftt.label")}
                      </dt>
                      <dd
                        data-testid="iftt"
                        aria-labelledby="costs-and-charges-details_openingIFTT"
                      >
                        {convertAmount(costsAndChargesData.openingIFTT)}
                      </dd>
                    </>
                  )}
                  <dt id="costs-and-charges-details_entry-cost">
                    {translate("other-costs-details.label")}
                  </dt>
                  <dd
                    data-testid="other-costs"
                    aria-labelledby="costs-and-charges-details_entry-cost"
                  >
                    {convertAmount(
                      getEntryCost(costsAndChargesData, instrumentType),
                    )}
                  </dd>
                </dl>
                <h3>{translate("service-costs")}</h3>
                <dl>
                  <dt id="costs-and-charges-details_opening-spread">
                    {translate("opening-spread.label")}
                  </dt>
                  <dd
                    data-testid="opening-spread"
                    aria-labelledby="costs-and-charges-details_opening-spread"
                  >
                    {convertAmount(costsAndChargesData.openingSpread)}
                  </dd>
                  <dt id="costs-and-charges-details_opening-commission">
                    {translate("commission.label")}
                  </dt>
                  <dd
                    data-testid="commission"
                    aria-labelledby="costs-and-charges-details_opening-commission"
                  >
                    {convertAmount(costsAndChargesData.openingCommission)}
                  </dd>
                  <dt id="costs-and-charges_opening-costs-fx-fee">
                    {translate("opening-fx-fee.label")}
                  </dt>
                  <dd
                    data-testid="opening-fx-fee"
                    aria-labelledby="costs-and-charges_opening-costs-fx-fee"
                  >
                    {convertAmount(costsAndChargesData.openingFxFee)}
                  </dd>
                </dl>
              </ExpandableSection>
            )}
            <ExpandableSection
              section="closing"
              costExpanded={costsExpanded.closing}
              updateCostsSectionState={() => {
                updateCostsSectionState("closing");
              }}
              total={convertAmount(costsAndChargesData.totalClosing)}
            >
              <h3>{translate("product-costs")}</h3>
              <dl>
                {costsAndChargesData.closingIFTT != null && (
                  <>
                    <dt id="costs-and-charges-details_closingIFTT">
                      {translate("iftt.label")}
                    </dt>
                    <dd
                      data-testid="iftt"
                      aria-labelledby="costs-and-charges-details_closingIFTT"
                    >
                      {convertAmount(costsAndChargesData.closingIFTT)}
                    </dd>
                  </>
                )}
                <dt id="costs-and-charges-details_exit-cost">
                  {translate("other-costs-details.label")}
                </dt>
                <dd
                  data-testid="other-costs"
                  aria-labelledby="costs-and-charges-details_exit-cost"
                >
                  {convertAmount(
                    getExitCost(
                      costsAndChargesData as ClosingCostsAndChargesResponseFormatted,
                      instrumentType,
                    ),
                  )}
                </dd>
              </dl>
              <h3>{translate("service-costs")}</h3>
              <dl>
                <dt id="costs-and-charges-details_closing-spread">
                  {translate("closing-spread.label")}
                </dt>
                <dd
                  data-testid="closing-spread"
                  aria-labelledby="costs-and-charges-details_closing-spread"
                >
                  {convertAmount(costsAndChargesData.closingSpread)}
                </dd>
                <dt id="costs-and-charges-details_closing-commission">
                  {translate("commission.label")}
                </dt>
                <dd
                  data-testid="commission"
                  aria-labelledby="costs-and-charges-details_closing-commission"
                >
                  {convertAmount(costsAndChargesData.closingCommission)}
                </dd>
                <dt id="costs-and-charges_closing-costs-fx-fee">
                  {translate("closing-fx-fee.label")}
                </dt>
                <dd
                  data-testid="closing-fx-fee"
                  aria-labelledby="costs-and-charges_closing-costs-fx-fee"
                >
                  {convertAmount(costsAndChargesData.closingFxFee)}
                </dd>
              </dl>
            </ExpandableSection>
            {isTicketForOpening && (
              <ExpandableSection
                section="daily"
                costExpanded={costsExpanded.daily}
                updateCostsSectionState={() => {
                  updateCostsSectionState("daily");
                }}
                total={convertAmount(costsAndChargesData.totalDaily)}
              >
                <p>{translate("daily-costs-fees-disclaimer")}</p>
                <h3>{translate("product-costs")}</h3>
                <dl>
                  {!("etpOngoingCost" in costsAndChargesData) &&
                    !("overnightFundingFee" in costsAndChargesData) && (
                      <>
                        <dt id="costs-and-charges-daily_no-product-costs">
                          {translate("none")}
                        </dt>
                        <dd
                          data-testid="product-none"
                          aria-labelledby="costs-and-charges-daily_no-product-costs"
                        >
                          -
                        </dd>
                      </>
                    )}
                  {"overnightFundingFee" in costsAndChargesData && (
                    <>
                      <Tooltip
                        attachment={{ y: yAxis.TOP, x: xAxis.LEFT }}
                        targetAttachment={{ y: yAxis.BOTTOM, x: xAxis.LEFT }}
                        offset={{ y: "-7px", x: "0" }}
                        content={
                          <TooltipContent>
                            <p>
                              {t(
                                "costs-and-charges.tooltips.dailyOvernightFundingFee",
                              )}
                            </p>
                          </TooltipContent>
                        }
                        pointerLocation={PointerLocation.TOP}
                        pointerPosition={10}
                      >
                        <dt
                          id="costs-and-charges-daily_overnight-funding-fee"
                          aria-label="overnight-funding-fee"
                        >
                          {translate("daily-overnight-funding-fee-turbo")}
                        </dt>
                      </Tooltip>
                      <dd
                        data-testid="overnight-funding-fee"
                        aria-labelledby="costs-and-charges-daily_overnight-funding-fee"
                      >
                        {convertAmount(costsAndChargesData.overnightFundingFee)}
                      </dd>
                    </>
                  )}
                  {"etpOngoingCost" in costsAndChargesData &&
                    instrumentType === InstrumentType.CONSTANT_LEVERAGE && (
                      <>
                        <dt id="costs-and-charges-daily_etp-ongoing-cost">
                          {translate("other-costs-details.label")}
                        </dt>
                        <dd
                          data-testid="ongoing-cost"
                          aria-labelledby="costs-and-charges-daily_etp-ongoing-cost"
                        >
                          {convertAmount(costsAndChargesData.etpOngoingCost)}
                        </dd>
                      </>
                    )}
                </dl>
                <h3>{translate("service-costs")}</h3>
                <dl>
                  <dt id="costs-and-charges-daily_no-service-costs">
                    {translate("none")}
                  </dt>
                  <dd
                    data-testid="service-none"
                    aria-labelledby="costs-and-charges-daily_no-service-costs"
                  >
                    -
                  </dd>
                </dl>
              </ExpandableSection>
            )}
          </ul>
        </section>
        <section className="total">
          <dl>
            <dt id="costs-and-charges-details_total-value">
              <h3>{translate("total")}</h3>
            </dt>
            <dd
              data-testid="total-costs"
              aria-labelledby="costs-and-charges-details_total-value"
            >
              {convertAmount(
                isTicketForOpening
                  ? costsAndChargesData.oneDayTotal
                  : costsAndChargesData.totalClosing,
              )}
            </dd>
            <dt id="costs-and-charges-details_percentage-notional-value">
              <Trans i18nKey={"costs-and-charges.details.notional-value-info"}>
                {/* The text below will not be rendered, it will be interpolated */}
                text
              </Trans>
            </dt>
            <dd
              data-testid="percentage-notional"
              aria-labelledby="costs-and-charges-details_percentage-notional-value"
            >
              {costsAndChargesData.percentageNotional}%
            </dd>
          </dl>
        </section>
        <section className="inducements">
          <h3>{translate("inducements")}</h3>
          <p>
            <Trans
              i18nKey={"costs-and-charges.details.inducements-info.content"}
            >
              {/* The text below will not be rendered, it will be interpolated */}
              text
              <a
                href={translate("inducements-info.link-url")}
                target="_blank"
                rel="noreferrer"
                className="etp-quick-deal_external-link etp-quick-deal_external-link--with-icon"
              >
                link
              </a>
            </Trans>
          </p>
        </section>
        <section className="download-costs-and-charges">
          <Button
            variant="unarmed"
            data-testid="download-button"
            onClick={(event) => {
              event.preventDefault();
              downloadPdfHook();
            }}
            size="small"
          >
            {translate("download-costs-and-charges")}
          </Button>
          <p data-testid="my-ig-info">{translate("my-ig-info")}</p>
        </section>
      </div>
    </CostsAndChargesDetailsContainer>
  );
};

export default memo(CostsAndChargesDetails);

const ExpandableSection = ({
  children,
  section,
  costExpanded,
  updateCostsSectionState,
  total,
}: ExpandableSectionProps) => {
  const { t } = useTranslation();

  const translate = (key: string) => {
    return t(`costs-and-charges.details.${key}`);
  };

  return (
    <li
      className={costExpanded ? "expanded" : ""}
      data-testid={`costs-and-charges-details_${section}-costs`}
    >
      <button type="button" onClick={updateCostsSectionState}>
        <span>
          <PlusMinusIcon
            type={
              costExpanded ? PlusMinusIconType.MINUS : PlusMinusIconType.PLUS
            }
          />
        </span>
        <dl>
          <dt id={`costs-and-charges-details_total-${section}-cost-value`}>
            {translate(`${section}-costs-total`)}
          </dt>
          <dd
            aria-labelledby={`costs-and-charges-details_total-${section}-cost-value`}
          >
            {total}
          </dd>
        </dl>
      </button>
      <div className="expandable">{children}</div>
    </li>
  );
};
